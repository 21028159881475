import Axios from "axios";
import axiosRetry from "axios-retry";
import { setupCache } from "axios-cache-interceptor";

import store from "store";
import { API_BASE_URL } from "libs/api/endpoints";
import { LOGIN_ROUTE, SIGNUP_ROUTE } from "consts";

const instance = Axios.create({
  ...(process.env.REACT_APP_ENV !== "prod" && {}),
  baseURL: API_BASE_URL,
  timeout: 15000,
  withCredentials: true,
});

const client = setupCache(instance, {
  ttl: 60 * 1000,
});

if (process.env.NODE_ENV !== "test") {
  const defaultRetryCount = 1;

  axiosRetry(instance, {
    retries: defaultRetryCount,
    onRetry: (retryCount, error, config) => {
      const axiosRetryConfig = {
        ...config["axios-retry"],
        retries: config["axios-retry"]?.retries || defaultRetryCount,
      };

      if (axiosRetryConfig.retries === retryCount) {
        store.ui.showServerErrorMessage(error, config);
      }
    },
  });
}

instance.interceptors.response.use(
  (res) => res.data,
  (error) => {
    if (error.config["axios-retry"].retryCount === 0) {
      store.ui.showServerErrorMessage(error, error.config);
    }

    if (error.response?.status === 401) {
      if (
        !store?.ui?.urlAfterSignIn &&
        ![LOGIN_ROUTE, SIGNUP_ROUTE].find((r) => window.location.pathname?.startsWith(r))
      ) {
        store?.ui.setUrlAfterSignIn(`${window.location.pathname}${window.location.search}`);
      }

      store.auth.setUserData(null);
      store.ui.closeAllUIElement();
    }

    return Promise.reject(error);
  },
);

instance.defaults.xsrfHeaderName = "X-CSRF-Token";
instance.defaults.xsrfCookieName = "csrf-token";

export default client;
