import { api } from "libs";
import { API_JOIN_WAITLIST, API_ORGS, API_SELF } from "libs/api/endpoints";
import { AuthOrg, AuthUser, FEATURE, PermissionsWithRole } from "store/types";

export const getSelf = async (): Promise<{ user: AuthUser; permissions: PermissionsWithRole; features: FEATURE[] }> =>
  api.get(API_SELF, {
    doNotShowErrorMessage: true,
  });

export const getOrgs = async (): Promise<AuthOrg[]> => {
  const res: { organizations: AuthOrg[] } = await api.get(API_ORGS, {
    errorMessage: {
      title: "Failed to load Organizations",
    },
  });

  return res?.organizations;
};

export const joinWaitlist = async (): Promise<void[]> =>
  api.post(API_JOIN_WAITLIST, null, {
    doNotShowErrorMessage: true,
  });
